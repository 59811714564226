import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import {
  Typography,
  Button,
} from '@material-ui/core';

import { history } from 'utils/history';
import { toast, getCurrentAccount } from 'utils/helpers';
import { CommonPanel } from 'components';
import {
  getDetails,
} from 'services/accounts';
import api from 'utils/api';

const SERVICES = {
    estimates: {
      title: 'Sąmatų skaičiavimai',    
    },
    investments: {
      title: 'Statinių palyginamieji rodikliai (kainynas)',    
    },
    ntk: {
      title: 'Statinių statybos ir atkuriamosios vertės skaičiavimai',
    },
    normsPbw: {
      title: 'Sustambintų darbų kainynas',
    },
    normsPrw: {
      title: 'Renovacijos darbų kainynas',
    },
    normsCalc: {
      title: 'Vienetinių ir sustambintų darbų kainų skaičiavimai',
    },
}

const LicenceRequestForm = ({ serviceCode }) => {
    const [companyAdmin, setCompanyAdmin] = useState(null);
    const [isCompanyAdmin, setIsCompanyAdmin] = useState(null);
    const [service, setService] = useState(null);

    const { t } = useTranslation();

    const getCompanyAdmin = async (accountId) => {
        const { company } = getCurrentAccount();
        if (!company) {
            console.log('Company not found for account');
            return;
        }

        const record = await getDetails(accountId);
        const owner = { fullName: record.company.admin, email: record.company.adminEmail, phone: record.company.adminPhone };

        setCompanyAdmin(owner);
    }

    const fetchAccountAndCompanyData = async () => {
        const currentAccount = await getCurrentAccount();
    
        const isCompanyAdmin = _.get(currentAccount, 'company.owner') == currentAccount._id;
        setIsCompanyAdmin(isCompanyAdmin);

        if (isCompanyAdmin) {
            setCompanyAdmin(currentAccount);
        } else {
            await getCompanyAdmin(currentAccount._id);
        }

        setService(SERVICES[serviceCode]);
    }

    useEffect(() => {
        fetchAccountAndCompanyData();
    }, []);
    
    
    const requestLicence = async () => {
        try {
            const response = await api.post(`/companies/licence-request`, {serviceTitle: service.title});
            if (response.status == 200) toast(t('Prašymas išsiųstas'), 'success');
        } catch (err) {
            toast(t('Nepavyko išsiųsti prašymo'), 'error')
        }
    }

    return (
        <div style={{padding: 20}}>
            <CommonPanel
                rootStyle={{ maxWidth: 400 }}
            >
                <>
                    <Typography>{t('Jūsų paskyrai nepriskirta paslaugos')} <strong>{service?.title}</strong> {t('licencija')}.</Typography>

                    {isCompanyAdmin ? (
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => history.push('/orders/create')}
                            style={{ marginTop: 20 }}
                        >
                            {t('Užsakyti paslaugą')}
                        </Button>
                    ) : (
                        <>
                            <Typography style={{ marginTop: 20 }}>
                                {t('Dėl paslaugų užsakymo kreipkitės į savo įmonės paskyros administratorių')}:
                            </Typography>
                            <Typography style={{ marginTop: 20, fontWeight: 'bold' }}>
                                {_.get(companyAdmin, 'fullName')} {_.get(companyAdmin, 'phone')} {_.get(companyAdmin, 'email')}
                            </Typography>
                            <Typography style={{ marginTop: 20, fontWeight: 'bold' }}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => requestLicence()}
                                    style={{ marginTop: 20 }}
                                >
                                    {t('Siųsti prašymą')}
                                </Button>
                            </Typography>
                        </>
                    )}
                </>
            </CommonPanel>
        </div>
    )
}

export default LicenceRequestForm;