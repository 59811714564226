import React from 'react';
import _ from 'lodash';
import moment from 'moment';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
// import { toast as toaster } from 'views/App/_actions';
import { toast as Toast } from 'react-toastify';
import { store } from './store';
import { setActive } from 'services/accounts';
import { history } from 'utils/history';
import { EDU_MODE, EDU_EMAILS } from 'utils/config';

const ERROR_MAP = {
  DiscountCodeNotValid: 'Nuolaidos kodas negalioja',
  DiscountCodeExpired: 'Nuolaidos kodas negalioja',
  DiscountCodeLicenceAmount: 'Nuolaidos kodas negalioja',
}

export const errorToText = code => {
  return ERROR_MAP[code];
}

export const useOutsideClick = (ref, callback) => {
  // const ref = React.useRef();

  React.useEffect(() => {
    const handleClick = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        callback();
      }
    };

    document.addEventListener('click', handleClick, true);

    return () => {
      document.removeEventListener('click', handleClick, true);
    };
  }, [ref]);

  return ref;
};

export const highlightText = (text, search) => {
  const matches = match(text, search);
  const parts = parse(text, matches);
  
  return (
    <div>
      {parts.map((part, index) => (
        <span key={index} className={part.highlight ? 'text-part-highlight' : ''}>
          {part.text}
        </span>
      ))}
    </div>
  );
}

export const replaceKeysDeep = (obj, keysMap) => {
  return _.transform(obj, function(result, value, key) {

    var currentKey = keysMap[key] || key;

    result[currentKey] = _.isObject(value) ? replaceKeysDeep(value, keysMap) : value;
  });
}

export const confirmDialog = (message) => {
  return window.confirm(message); // eslint-ignore-line
}

export const arrayMove = (arr, fromIndex, toIndex) => {
  const element = arr[fromIndex];
  arr.splice(fromIndex, 1);
  arr.splice(toIndex, 0, element);

  return arr;
}

// TODO move usage of toast from views/App/_actions to this helper and update src everywhere
// export const toast = (message, type = 'info') => {
//   toaster(message, type);
// }

export const toast = (message, type = 'info', autoClose = 10000) => {
  try {
    Toast[type](message, { autoClose });
  } catch (err) {
    console.log(`Toast: ${message} (type [${type}] not supported)`);
  }
}

export const getQueryVariable = variable => {
  const query = window.location.search.substring(1);
  const vars = query.split('&');

  for (let i=0; i<vars.length; i++) {
    const pair = vars[i].split('=');

    if(pair[0] == variable) return pair[1];
  }
  
  return false;
}

/**
 * Checks if current account has access to given functionality.
 * 
 * @param {String} roleName 
 * @returns {Boolean}
 */
export const canAccess = (roleName) => {
  // If comma separated - multiple permissions allowed
  const multiple = roleName.indexOf(',') > -1;

  try {
    const { roles } = store.getState().app.currentAccount;
    // console.log({ roles });
    if (!multiple) {
      return _.includes(roles, roleName);
    } else {
      return _.some(roles, role => _.includes(roleName.split(','), role));
    }
  } catch (err) {
    console.error('Access not allowed', err);
    return false;
  }
}

export const isAdminCurrent = () => {
  const isAdmin = canAccess('administrator.layout');
  
  return isAdmin;
}

export const getCurrentUser = () => {
  try {
    return store.getState().app.user;
  } catch (err) {
    console.log('User details not available');
    return {};
  }
}

export const validateEmail = email => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export const userIsInWrongInstance = email => {
  // No need to validate if in educational mode
  if (EDU_MODE) return false;

  // Check if email is in list of educational emails on non-edu mode
  const shouldPreventAction = EDU_EMAILS.some(eduEmail => email.endsWith(eduEmail));

  if (shouldPreventAction) {
    console.log('Wrong instance for', email);

    toast(<>Prisijungimas mokymosi įstaigoms<br /> <a className="edu-link" href="https://edu.bimgates.lt">edu.bimgates.lt</a></>, 'error');
  }

  return shouldPreventAction;
}

export const setActiveAccount = async (accountId, showToast = true, redirectTo, redirectWithReload = false) => {
  const { account, token } = await setActive(accountId);
  
  // Update JWT token in local storage
  localStorage.setItem('jwt', token);

  // TODO remove when movimg from redux to mobx
  store.dispatch({ type: 'ACCOUNTS_SET_ACCOUNT_SUCCESS', account });
  
  // Showing toast conflicts with changing URL with location.href
  if (showToast) toast(`Pasirinkita paskyra: ${account.position}`);

  if(redirectTo && !redirectWithReload) history.push(redirectTo);
  if(redirectTo && redirectWithReload) window.location.href = redirectTo;
}

export const getCurrentAccount = () => {
  try {
    return store.getState().app.currentAccount;
  } catch (err) {
    console.log('Account details not available');
    return {};
  }
}

export const loadState = () => {
  console.log('Loading state');

  try {
    // Check if token is present
    if (localStorage.getItem('jwt') == null) {
      return undefined;
    }

    const serializedState = localStorage.getItem('state');
    if (serializedState === null) {
      return undefined;
    }
    
    const loadedState = JSON.parse(serializedState);

    // Validate state
    try {
      if (loadedState.app.user === undefined) {
        return undefined;
      }

      if (loadedState.app.currentAccount === undefined) {
        return undefined;
      }


    } catch (err) {
      console.log('State invalid', err);
      return undefined;
    }

    return loadedState;
  } catch (err) {
    return undefined;
  }
}

export const saveState = (state) => {
  try {
    // Check if token is present
    if (localStorage.getItem('jwt') == null) {
      return undefined;
    }
    
    const serializedState = JSON.stringify(state);
    localStorage.setItem('state', serializedState);
  } catch (err) {
    console.log('Cannot save state to localStorage');
  }
}

/**
 * Show at least 2 decimal numbers. Will show more than 2 if present.
 * 
 * @param {Number|String} value 
 */
export const twoOrMoreDecimals = (value = 0) => {
  try {
    return value.toFixed(Math.max(2, (value.toString().split('.')[1] || []).length));
  } catch (err) {
    return value;
  }
}

export const twoDecimals = (value = 0) => {
  return (Math.round(value * 100) / 100).toFixed(2);
}

/**
 * Scroll to DOM element (smooth).
 * 
 * @param {Object} element 
 */
 export const scrollToElement = (element, isEmbeded = false) => {

  if (!isEmbeded) {
    window.scrollTo({
      'behavior': 'smooth',
      'left': 0,
      'top': element.offsetTop
    });
  } else {
    element.scrollIntoView();
  }
}

/**
 * Temporary highlights object background to standout.
 * 
 * @param {Object} element 
 */
export const blinkElement = (element, highlightTimeout = 2000) => {
  var f = element;
  setTimeout(function() {
    f.classList.add('highlight');
  }, 500);
  setTimeout(function() {
    f.classList.remove('highlight');
  }, highlightTimeout);
}

/**
 * Toggle highlights on object background to standout.
 * 
 * @param {Object} element 
 */
 export const setElementHighlight = (element = null, isHighlighted = false) => {
   if (element) {
    if (isHighlighted) {
      element.classList.add('highlight');
    } else {
      element.classList.remove('highlight');
    }
  } else {
    const elems = document.querySelectorAll('.highlight');

    [].forEach.call(elems, function(el) {
        el.classList.remove('highlight');
    });
  }
}

/**
 * Removes anything that's not a number from a string.
 * 
 * @param {String} value 
 */
export const clearNonDigits = value => {
  return value ? value.replace(/\D+/g, '') : '';
}

/**
 * Converts object to URL query string.
 * 
 * @param {Object} obj 
 */
export const objectToQueryString = obj => {
  if (!obj) {
    return null;
  }

  const results = [];
  _.forOwn(obj, (value, key) => {
    if (Array.isArray(value)) {
      _.forOwn(value, value => {
        results.push(`${key}=${value}`);
      });
    } else {
      results.push(`${key}=${value}`);
    }
  });
  return results.join('&');
};

export const timestampToDate = (value, format = 'YYYY-MM-DD') => {
  try {
    return moment(value).utc().format(format);
  } catch (err) {
    return null;
  }
}

export const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, {type: contentType});
  return blob;
}

export const blobToFileDownload = (name, blob) => {
  // Trigger file download
  const fileURL = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = fileURL;
  link.download = name;
  link.click();
}

export const base64ToFileDownload = ({ base64Data, name, type }) => {
  let mime;
  switch (type) {
    case 'xlsx':
      mime = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      break;
    case 'docx':
      mime = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
      break;
    default:
      throw new Error('Download type not supported');
  }

  const blob = b64toBlob(base64Data, mime);

  blobToFileDownload(name, blob);
}