import React, { Component } from 'react';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';

import { Auth as AuthLayout } from 'layouts';
import styles from './styles';
import { 
  TextField,
  Button,
  withStyles,
} from '@material-ui/core';

import { userService } from 'views/Users/_services';
import { toast, userIsInWrongInstance } from 'utils/helpers';
import { history } from 'utils/history';
import { EDU_MODE } from 'utils/config';

class PasswordReset extends Component {
  
  state = {
    email: '',
    hash: null,
    password: '',
    passwordConfirm: '',
    loading: false,
  }

  componentDidMount() {
    const { match } = this.props;

    if (match.params.hash) {
      this.setState({ hash: match.params.hash });
    }
  }

  async handleResetSubmit() {
    const { t } = this.props;
    const { email } = this.state;

    if (!email) {
      return;
    }

    if (userIsInWrongInstance(email)) return;

    this.setState({ loading: true });

    const { exists, active } = await userService.checkEmail(email);

    if (exists && active) {
      // Exists and activated
      // TODO retrieve expirational hash so reset link can be invalidated automatically
      await userService.resetPassword({ email });
      toast(t('Slaptažodžio keitimo nuoroda nusiųsta el. paštu'), 'success');
      this.setState({ email: '' });
    } else if (exists && !active) {
      // Exists, inactive
      toast(t('Vartotojas yra neaktyvuotas, todėl slaptažodžio keitimas negalimas'), 'error');
      history.push('/login');
    } else {
      // User not exist
      toast(t('Vartotojas neegzistuoja'), 'error');
      history.push('/login');
    }

    this.setState({ loading: false });
  }

  async handlePasswordSubmit() {
    const { t } = this.props;
    const { password, confirmPassword, hash } = this.state;

    if (!password || !confirmPassword || password != confirmPassword) {
      toast(t('Slaptažodžiai nesutampa'), 'error');
      return;
    }

    this.setState({ loading: true });

    await userService.resetPassword({ password, confirmPassword, hash });

    toast(t('Slaptažodis pakeistas. Galite prisijungti.'), 'success');
    localStorage.removeItem('jwt');
    localStorage.removeItem('state');
    this.setState({ loading: false });
    history.push('/login');
  }

  handleFieldChange = (field, value) => {
    this.setState({ 
      [field]: value,
    });
  }

  renderEmailForm() {
    const { classes, t } = this.props;
    const { email } = this.state;

    return (
      <React.Fragment>
        <TextField
          className={classes.textField}
          label={t('El. p. adresas')}
          name="email"
          onChange={event =>
            this.handleFieldChange('email', event.target.value)
          }
          type="text"
          value={email || ''}
          autoComplete="off"
        />
        
        <Button
          className={classes.SignUpButton}
          color="primary"
          onClick={() => this.handleResetSubmit()}
          size="large"
          variant="contained"
          disabled={!email}
        >
          {t('Siųsti slaptažodžio keitimo nuorodą')}
        </Button>
      </React.Fragment>
    )
  }

  renderPasswordForm() {
    const { classes, t } = this.props;
    const { password, confirmPassword } = this.state;

    return (
      <React.Fragment>
        <TextField
          className={classes.textField}
          label={t('Naujas slaptažodis')}
          name="password"
          onChange={event =>
            this.handleFieldChange('password', event.target.value)
          }
          type="password"
          value={password || ''}

          autoComplete="new-password-reset"
        />

        <TextField
          className={classes.textField}
          label={t('Slaptažodžio patvirtinimas')}
          name="confirmPassword"
          onChange={event =>
            this.handleFieldChange('confirmPassword', event.target.value)
          }
          type="password"
          value={confirmPassword || ''}

          autoComplete="new-password-reset-confirm"
        />
        
        <Button
          className={classes.SignUpButton}
          color="primary"
          onClick={() => this.handlePasswordSubmit()}
          size="large"
          variant="contained"
          disabled={!password && !confirmPassword && password != confirmPassword}
        >
          {t('Pakeisti slaptažodį')}
        </Button>
      </React.Fragment>
    )
  }

  render() {
    const { classes, t } = this.props;
    const { loading, hash } = this.state;

    return (
      <AuthLayout title={<>{t('Slaptažodžio keitimas')} {EDU_MODE && <span className="edu-notice">({t('mokymosi įstaigoms')})</span>}</>} loading={loading}>
        <div className={classes.root}>
          <div className={classes.fields}>
            {!hash ? this.renderEmailForm() : this.renderPasswordForm()}
          </div>
        </div>
      </AuthLayout>
    );
  }
}

export default compose(
  withStyles(styles),
  withRouter,
  withTranslation(),
)(PasswordReset);