import { observable, action, decorate, toJS } from 'mobx';
import _ from 'lodash';
import { history } from 'utils/history';
import { 
  toast,
  canAccess,
  getCurrentAccount,
  getCurrentUser,
  validateEmail,
  base64ToFileDownload,
} from 'utils/helpers';

import {
  create,
  update,
  calculate,
} from 'services/orders';
import { getAll as getAllServices } from 'services/services';
import { getAll as getAllServicePackages } from 'services/servicePackages';
import { getAll as getAllCompanies, getOne as getOneCompany } from 'services/companies';
import { getOne as getOneOrder, getDocument, renewal } from 'services/orders';
import { getSettings } from 'services/settings';

const DEFAULT_RECORD = {
  months: 12,
  status: 'draft',
}

class Store {
  record = { ...DEFAULT_RECORD };
  data = {};
  isReadonly = true;
  isAdmin = false;
  isValidForSubmit = false;
  settings = {};

  // Action
  reset = () => {
    this.record = { ...DEFAULT_RECORD };
    this.data = {};
    this.isReadonly = true;
    this.isAdmin = false;
    this.isValidForSubmit = false;
    this.settings = {};
  }

  // Action
  setSettings = settings => {
    this.settings = settings;
  }

  // Action
  setRecord = (record = {}) => {
    this.record = record;
  }

  // Action
  setData = (data = {}) => {
    this.data = data;
  }

  // Action
  setIsValidForSubmit = () => {
    this.isValidForSubmit = this.validateForSubmit();
  }

  // Action
  setIsReadonly = isReadonly => {
    this.isReadonly = isReadonly;
  }

  // Action
  setIsAdmin = () => {
    const isAdmin = canAccess('administrator.layout');
    this.isAdmin = isAdmin;
  }

  // Action
  setRecordValue = (field, value) => {
    // this.record[field] = value;

    _.set(this.record, field, value);
    if (field.includes('items')) {
      console.log({value});
      _.set(this.record, field.replace('price', 'customPrice'), value);
      field = field.replace(/[^a-z]/gi, "");
    }

    switch(field) {
      case 'totals.discountPercent':
        _.set(this.record, 'totals.discountAmount', 0);
        _.set(this.record, 'totals.correctedTotal', null);
        this.doRecalc();
        break;

      case 'totals.discountAmount':
        _.set(this.record, 'totals.discountPercent', 0);
        _.set(this.record, 'totals.correctedTotal', null);
        this.doRecalc();
        break;

      case 'totals.correctedTotal':
        this.doRecalc();
        break;

      case 'totals.total':
        _.set(this.record, 'customTotal', value);
        this.doRecalc();
        break;

      case 'itemsprice':
        this.doRecalc();
        break;

      case 'months':
        this.setRecordValue('totals.correctedTotal', null);
        this.doRecalc();
        break;
      
      case 'company':
        this.loadCompanyForOrder();
        break;

      case 'enableReminders':
        this.updateReminderPreferences();
        break;

      case 'paymentDelayed':
        this.updatePaymentDelayedStatus();
        break;  

      default:
        break;
    }

    this.setIsValidForSubmit();
    this.configAccess();
  }

  resetDiscountCode = async () => {
    if (_.get(this.record, 'discountCode')) {
      this.record.discountCode = '';
      this.record.totals.discountAmount = this.record.totals.discountPercent = 0;
    }
    await this.recalculate();
  }  

  doRecalc = _.debounce(() => this.recalculate(), 500)

  addItem = async (item) => {
    let items = this.record.items || [];
    items.push(item);

    this.setRecordValue('items', items);
    this.setRecordValue('totals.correctedTotal', null);
    await this.recalculate();
  }

  updateItem = async (idx, data) => {
    let items = this.record.items;
    items[idx] = { ...data };

    this.setRecordValue('items', items);
    this.setRecordValue('totals.correctedTotal', null);
    await this.recalculate();
  }

  removeItem = async (idx) => {
    let items = this.record.items;

    if (!items || !items.length) return;

    items.splice(idx, 1);
    this.setRecordValue('totals.correctedTotal', null);
    await this.recalculate();
  }
  
  getServicePackageTitle = _id => {
    const servicePackage = this.data.servicePackages ? _.find(this.data.servicePackages, { _id }) : null;

    return servicePackage 
      ? servicePackage.title 
      : 'Individualus';
  }

  recalculate = async () => {
    const record = { ...this.record };

    try {
      const recalculated = await calculate(record);
      this.setRecord(recalculated);
    } catch (err) {
      throw err;
    }
  }

  validateForSubmit = () => {
    const { record } = this;
    if(!record || (record && !record.items) || (record.items && !record.items.length)) return false;

    return true;
  }

  shouldPreventSubmit = () => {
    const { record, data } = this;
    const isCompany = _.get(data, 'company.type') !== 'personal';

    if (!record.clientName) return 'Neįvestas pirkėjas';
    if (isCompany && !record.clientCode) return 'Neįvestas įmonės kodas';
    if (!record.clientAddress) return 'Neįvestas adresas';
    if (!record.clientEmail) return 'Neįvestas el. paštas į kurį bus siunčiama sąskaita';
    if (!validateEmail(record.clientEmail)) return 'Netinkama el. pašto adreso struktūra';

    return false;
  }

  create = async (submit = false) => {
    const record = toJS(this.record);

    try {
      const order = await create(record, submit ? { submit } : null);
      toast(`Užsakymas ${order.number} ${submit ? 'pateiktas. Sąskaita išankstiniam apmokėjimui išsiųsta į ' + record.clientEmail : 'išsaugotas'}`, 'success');
      if (!submit) {
        history.push(`/orders/${order._id}`);
      } else {
        history.push('/orders');
      }
    } catch (err) {
      toast('Nepavyko pateikti užsakymo', 'error');
    }
  }

  update = async (submit = false) => {
    const record = toJS(this.record);

    try {
      const order = await update(record._id, record, submit ? { submit } : null);
      toast(`Užsakymas ${order.number} ${submit ? 'pateiktas. Sąskaita išankstiniam apmokėjimui išsiųsta į ' + record.clientEmail : 'atnaujintas'}`, 'success');
      if (!submit) {
        history.push(`/orders/${order._id}`);
      } else {
        history.push('/orders');
      }
    } catch (err) {
      toast('Nepavyko atnaujinti užsakymo', 'error');
    }
  }

  loadData = async (orderId = null) => {
    this.setIsAdmin();

    const settings = await getSettings();
    this.setSettings(settings);

    let data = {};
    data.services = await getAllServices();
    data.servicePackages = await getAllServicePackages();    
    if (this.isAdmin) {
      data.companies = await getAllCompanies();
    }
    this.setData(data);
    
    if (orderId) await this.loadRecord(orderId);

    await this.loadCompanyForOrder();

    this.configAccess();
    this.prefillClientDetails();
    this.setIsValidForSubmit();
  }

  loadRecord = async (orderId) => {
    const record = await getOneOrder(orderId);

    this.setRecord(record);
  }

  configAccess = () => {
    let isReadonly = true;
    if (this.record.status === 'draft' || (this.isAdmin && this.record.status === 'pending')) {
      isReadonly = false;
    }
    this.setIsReadonly(isReadonly);
  }

  loadCompanyForOrder = async () => {
    if (this.isAdmin && !this.record.company) return;

    const companyId = this.isAdmin 
      ?  _.get(this.record, 'company._id', this.record.company)
      : _.get(getCurrentAccount(), 'company.id');
    
    const company = await getOneCompany(companyId);

    this.setData({ ...this.data, company });
    this.prefillClientDetails();
    this.setIsValidForSubmit();
  }

  prefillClientDetails = () => {
    const { company } = this.data;
    if (!company || this.record._id) return;

    const currentUser = getCurrentUser();
    const currentAccount = getCurrentAccount();
    const { record } = this;

    const isCompany = company.type !== 'personal';
    const clientName = isCompany ? company.name : currentUser.fullName;
    const clientEmail = isCompany ? company.email : currentUser.email;
    const clientPhone = isCompany ? company.phone : currentUser.phone;

    record.clientName = record.clientName || clientName;
    record.clientAddress = record.clientAddress || company.address;
    record.clientCode = record.clientCode || isCompany ? company.code : null;
    record.clientVat = record.clientVat || company.vat;
    record.clientEmail = record.clientEmail || clientEmail;
    record.clientPhone = record.clientPhone || clientPhone;

    if (currentAccount.isAdmin) return;
    const ownerDetails = `${currentUser.fullName} El. p.: ${currentUser.email || '-'} Tel.: ${currentUser.phone || '-'}`;
    record.ownerDetails = record.ownerDetails || ownerDetails;
  }

  downloadDocument = async (documentType) => {
    const { doc, filename } = await getDocument(this.record._id, documentType);

    let type;
    switch (documentType) {
      case 'prepayment':
        type = 'docx';
        break;
      case 'invoice':
        type = 'docx';
        break;
      default:
        throw new Error('Document type not supported');
    }

    base64ToFileDownload({ base64Data: doc, name: filename, type });
  }

  sendGeneratedInvoice = async (submit = true) => {
    const record = toJS(this.record);
    const order = await update(record._id, {...record, sendInvoice: true}, { submit });

    return order;
  }

  updateReminderPreferences = async () => {

    try {
      await update(this.record._id, { enableReminders: this.record.enableReminders });
      toast('Pranešimų pasirinkimas atnaujintas', 'success');
    } catch (err) {
      toast('Nepavyko pakeisti pranešimų pasirinkimo', 'error');

      this.setRecord({
        ...this.record,
        enableReminders: !this.record.enableReminders,
      })
    }
  }

  updatePaymentDelayedStatus = async () => {

    try {
      await update(this.record._id, { paymentDelayed: this.record.paymentDelayed });
    } catch (err) {
      toast('Nepavyko pakeisti mokėjimo atidėjimo statuso', 'error');

      this.setRecord({
        ...this.record,
        paymentDelayed: !this.record.paymentDelayed,
      })
    }
  }

  renewal = async () => {
    const { _id } = await renewal(this.record._id);
    return _id;
  }
}

decorate(Store, {
  record: observable,
  data: observable,
  isReadonly: observable,
  isAdmin: observable,
  isValidForSubmit: observable,
  setIsValidForSubmit: action,
  setRecord: action,
  setData : action,
  setRecordValue: action,
  setIsReadonly: action,
  setIsAdmin: action,
  reset: action,
  prefillClientDetails: action,
});

export default new Store;