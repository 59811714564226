import React, { Component } from 'react';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';

import { Auth as AuthLayout } from 'layouts';
import styles from './styles';
import { 
  withStyles,
  TextField,
  Button,
  Typography,
} from '@material-ui/core';

import { login } from 'views/Users/_actions';
import { RECAPTCHA_SITE_KEY, EDU_MODE } from 'utils/config';
import { userIsInWrongInstance } from 'utils/helpers';

class SignIn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      firstName: '',
      lastName: '',
      phone: '',
      redirectTo: null,
    };
    this._reCaptchaRef = React.createRef();
  }

  componentWillMount() {
    try {
      // Attemtp to get intended URL to redirect after login
      const redirectTo = this.props.location.state.from.pathname;
      this.setState({ redirectTo });
    } catch (err) {
      // Do nothing
    }

    if (this.props.app.user) {
      this.props.history.push('/');
    }
  }

  handleFieldChange = (field, value) => {
    this.setState({ 
      [field]: value,
    });
  }

  async handleSignIn() {
    const { dispatch } = this.props;
    const { email, password, redirectTo } = this.state;

    if (userIsInWrongInstance(email)) return;

    const captchaToken = await this._reCaptchaRef.current.executeAsync();
  
    if (!captchaToken) console.log('reCaptcha token missing');

    if (email && password && captchaToken) {
      dispatch(login(email, password, redirectTo, captchaToken));
    }
  }

  handleEnterSignIn = (event) => {
    if (event.key === "Enter") {
        this.handleSignIn();
    }
  }

  render() {
    const { classes, email, password, t } = this.props;
    
    if (!RECAPTCHA_SITE_KEY) return 'Recaptcha not availaible';

    return (
      <AuthLayout title={<>{t('Prisijungti')} {EDU_MODE && <span className="edu-notice">({t('mokymosi įstaigoms')})</span>}</>}>
        <Typography variant="body1" style={{ marginTop: 5, opacity: 0.8 }}>
          {t('Nerekomenduojame naudoti interneto naršyklių versijų, kurių atnaujinimus gamintojas nutraukė (pvz. Internet Explorer)')}
        </Typography>

        <div className={classes.root}>
          <div className={classes.fields}>
            <TextField
              className={classes.textField}
              label={t('El. paštas')}
              name="email"
              onChange={event =>
                this.handleFieldChange('email', event.target.value)
              }
              type="email"
              value={email}
              InputLabelProps={{ shrink: true }}
              onKeyDown={(e) => this.handleEnterSignIn(e)}
            />
            <TextField
              className={classes.textField}
              label={t('Slaptažodis')}
              name="password"
              onChange={event =>
                this.handleFieldChange('password', event.target.value)
              }
              type="password"
              value={password}
              InputLabelProps={{ shrink: true }}
              onKeyDown={(e) => this.handleEnterSignIn(e)}
            />
          </div>
          <Button
            className={classes.signInButton}
            color="primary"
            onClick={() => this.handleSignIn()}
            size="large"
            variant="contained"
          >
            {t('Prisijungti')}
          </Button>

          {/* Signup */}
          <Typography
            className={classes.signUp}
            variant="body1"
            style={{ textAlign: 'center' }}
          >
            {t('Dar neturite paskyros?')} &nbsp;
            <Link
              className={classes.signUpUrl}
              to="/register"
            >
              {t('Registruotis')}
            </Link>
          </Typography>

          {/* Reset password */}
          <Typography
            className={classes.signUp}
            variant="body1"
            style={{ textAlign: 'center' }}
          >
            <Link
              className={classes.signUpUrl}
              to="/password-reset"
            >
              {t('Pamiršau slaptažodį')}
            </Link>
          </Typography>
        </div>

        <ReCAPTCHA
          size="invisible"
          ref={this._reCaptchaRef}
          sitekey={RECAPTCHA_SITE_KEY}
          onChange={value =>
            this.handleFieldChange('captcha', value)
          }
        />
      </AuthLayout>
    );
  }
}

const mapStateToProps = (state) => {
  return { ...state };
}

export default compose(
  withStyles(styles),
  connect(mapStateToProps),
  withTranslation(),
)(SignIn);
