import React, { Component } from 'react';
import _ from 'lodash';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {
  withStyles,
  Grid,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import { Dashboard as DashboardLayout } from 'layouts';
import { NtkFilters, NtkMaterialPickerHorizontal } from 'components';
import { NtkResults } from './components';

import styles from './styles';
import { canAccess, getQueryVariable, getCurrentAccount } from 'utils/helpers';
import {
  getTypes,
  valueChanged,
  resetForm,
} from './_actions';
import { TrialHandler, LicenceRequestForm } from 'components';
import { EDU_MODE } from 'utils/config';

import api from 'utils/api';

// Inject reducers
import { store } from 'utils/store';
import { ntkViewer } from './_reducers';
store.injectReducer('ntkViewer', ntkViewer);

const SERVICE_CODE = 'ntk';
const SERVICE_CODE_DB = 'BIMGates_NTK_BA';

class NtkViewer extends Component {
  state = {
    isTrial: false,
    showTrialMessage: false,
    canAccessService: false,
  }

  componentWillMount() {
    const { dispatch } = this.props;
    dispatch(resetForm());
  }

  async componentDidMount() {
    if (getQueryVariable('trial')) {
      this.setState({ isTrial: true });
      return;
    }

    const response = await api.post(`/licences/licence-valid`, { service: SERVICE_CODE_DB });
    this.setState({ canAccessService: response.data.validLicence });

    if (_.includes(getCurrentAccount().trialServices, SERVICE_CODE)) {
      this.setState({ showTrialMessage: true });
    }

    const { dispatch } = this.props;

    if (response.data.validLicence) dispatch(getTypes());
  }

  handleChange({ name, value }) {
    if (!name) {
      return;
    }

    console.log({ name, value });
    const { dispatch } = this.props;
    dispatch(valueChanged({ name, value }));
  }

  renderService() {
    const {
      classes,

      type,
      buildingType,
      pricing,
      
      types,
      versions,
      hierarchy,
      materials,
      initialMaterials,
      prices,
      combinations,
      files,
    } = this.props;

    return (
      <div className={classes.root} style={{ maxWidth: '1200px' }}>
        <Grid container spacing={0}>

          <Grid item lg={5} xl={5} sm={12} xs={12}>
            
            <div className={classes.panel}>
              <NtkFilters
                filtersOnly                
                type={type}
                buildingType={buildingType}
                pricing={pricing}
                
                types={types}
                versions={versions}
                hierarchy={hierarchy}
                
                handleChange={this.handleChange.bind(this)}
              />
            </div>
            
          </Grid>

          <Grid item xs={12}>
            
            {materials && materials.length ? (
              <div className={classes.panel}>
                <NtkMaterialPickerHorizontal
                  materials={materials}
                  initialMaterials={initialMaterials}
                  handleChange={this.handleChange.bind(this)}
                />
              </div>
            ) : null}
            
          </Grid>

          <Grid item xs={12}>
            
            {prices ? (
              <div className={classes.panel}>
                <NtkResults
                  files={files}
                  type={type}
                  materials={materials}
                  buildingType={buildingType}
                  data={prices}
                  combinations={combinations}
                  version={pricing.code}
                  dispatch={this.props.dispatch}
                />
              </div>
            ) : null}

          </Grid>

        </Grid>
      </div>
    )
  }

  renderTrial() {
    return (
      <div style={{ margin: 20 }}>
        <TrialHandler
          service={SERVICE_CODE}
        />
      </div>
    )
  }

  renderTrialMessage() {
    const { t } = this.props;

    return EDU_MODE ? null : (
      <div style={{ margin: 10 }}>
        <Alert 
          variant="filled"
          severity="info"
          // onClose={() => this.setState({ showTrialMessage: false })}
        >
          {t('Jūs naudojate bandomąją (laikinai nemokamą) paslaugos versiją.')}
        </Alert>
      </div>
    )
  }

  renderLicenceRequest() {

    return (
      <LicenceRequestForm
        serviceCode={SERVICE_CODE}
      />
    )
  }

  render() {
    const { isTrial, canAccessService, showTrialMessage } = this.state;
    const { isLoading, t } = this.props;

    return (
      <DashboardLayout 
        title={`${t('NTK paieška')} ${canAccess('ntk.demo') ? 'DEMO' : ''}`}
        isContentLoading={isLoading}
        breadcrumbs={[
          { title: t('Žinių bazė') },
          { title: t('NTK paieška') },
        ]}
        beforeMainContent={!isTrial && showTrialMessage && this.renderTrialMessage()}
      >

        {!isTrial ? canAccessService ? this.renderService() : this.renderLicenceRequest() : this.renderTrial()}

      </DashboardLayout>
    );
  }
}

const mapStateToProps = ({ ntkViewer, dispatch }) => {
  return {
    ...ntkViewer,
    dispatch,
  }
}

export default compose(
  withStyles(styles),
  connect(mapStateToProps),
  withTranslation(),
)(NtkViewer);
