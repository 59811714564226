import React from 'react';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';
import {
  withStyles,
  Button,
} from '@material-ui/core';
import { observer } from 'mobx-react';

import { FormField2, CommonPanel, LicenceManager } from 'components';
import Accounts from 'views/Accounts';

import { toast, setActiveAccount, getCurrentAccount } from 'utils/helpers';
import styles from './styles';

class Container extends React.Component {
  state = {
    isCompanyAdmin: false,
    currentAccountId: null,
  }

  componentDidMount() {
    const { match, store } = this.props;
    const currentAccount = getCurrentAccount();
    this.setState({currentAccountId: currentAccount?.id});

    const isCompanyAdmin = currentAccount._id == _.get(currentAccount, 'company.owner');
    this.setState({ isCompanyAdmin });

    if (_.get(match, 'params.id')) {
      if (_.get(match, 'params.action') === 'switch') {
        setActiveAccount(match.params.id, true, '/');
        return;
      }

      store.getOne(match.params.id);
    }

    store.loadOptions();
  }

  componentWillUnmount() {
    this.props.store.reset();
  }

  onChange({ name, value }) {
    const { store } = this.props;

    store.setRecord({
      ...store.record,
      [name]: value,
    });
  }

  submitForm(type = null) {
    const { store, t } = this.props;

    if (!_.get(store.record, 'user') || !_.get(store.record, 'company') || !_.get(store.record, 'position')) {
      toast(t('Vartotojas, įmonė ir pareigos privalo būti nurodyti'), 'warning');
      return;
    }

    if (type == 'acceptUser') {
      store.record.status = 'active';
      store.update(type);
      return;
    }

    if (type == 'denyUser') {
      store.record.status = 'inactive';
      store.update();
      return;
    }

    if (store.record._id) {
      store.update();
    } else {
      store.create();
    }
  }

  renderFormField({ name, ...field }) {
    const { record } = this.props.store;

    return (
      <FormField2
        {...field}
        name={name}
        // autoComplete={`project-${name}`}
        key={name}
        value={record ? _.get(record, name) : (field.multiple ? [] : '')}
        onChange={this.onChange.bind(this)}
      />
    );
  }

  renderForm() {
    const { options, isAdmin, record } = this.props.store;
    const { t, store, match } = this.props;

    const enableStatusEdit = isAdmin || this.state.isCompanyAdmin && !store?.record?.isPersonal && this.state.currentAccountId != _.get(match, 'params.id');

    return (
      <>
        { record != null ? (isAdmin ? this.renderFormField({ name: 'user._id', label: t('Priklauso vartotojui'), type: 'select', options: options.users }) 
          : this.renderFormField({ name: 'user.fullName', label: t('Vardas'), disabled: true})) : null}

        { record != null ? (!isAdmin  ? this.renderFormField({ name: 'user.email', label: t('El. paštas'), disabled: true}) : null) 
          : null} 

        {record != null ? (!isAdmin ? this.renderFormField({ name: 'user.phone', label: t('Telefono nr.'), disabled: true }) : null)
          : null} 

        {isAdmin ? this.renderFormField({ name: 'company', label: t('Įmonė'), type: 'select', options: options.companies }) : null} 

        {this.renderFormField({ name: 'status', label: t('Būsena'), type: 'select', options: options.status, disabled: !enableStatusEdit })}

        {this.renderFormField({ name: 'position', label: t('Pareigos'), type: 'text' })}

        {this.renderFormField({ name: 'comment', label: t('Pastabos'), type: 'text' })}

        {isAdmin ? this.renderFormField({ name: 'roleGroups', label: t('Priskirtos teisių grupės'), type: 'select', options: options.permissionGroups, multiple: true }) : null}
      </>
    )
  }
  
  render() {
    const { classes, store, t } = this.props;
    const title = !store.record ? t('Nauja paskyra') : t('Paskyros redagavimas');

    const adminOrCompanyOwner = this.state.isCompanyAdmin || store.isAdmin;

    return (
      <Accounts
        title={title}
        breadcrumbs={[
          { title: t('Administravimas') },
          { title: t('Paskyros'), to: '/accounts' },
          { title },
        ]}
      >
        <div className={classes.wrap}>
          
          <CommonPanel
            footer={(
              <div>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={this.submitForm.bind(this)}
                >
                  {t('Išsaugoti')}
                </Button>
                { store?.record?.isPersonal === false && adminOrCompanyOwner && store.record ?
                  <div style={{float: "right"}}>
                    <Button
                      color="primary"
                      variant="contained"
                      type="acceptUser"
                      onClick={this.submitForm.bind(this, 'acceptUser')}
                    >
                      {t('Patvirtinti ir priskirti paslaugas')}
                    </Button>
                    <Button
                      color="secondary"
                      variant="contained"
                      onClick={this.submitForm.bind(this, 'denyUser')}
                      style={{ marginLeft: "10px" }}
                    >
                      {t('Atmesti paskyrą')}
                    </Button>
                  </div>
                : null
                }
              </div>
            )}
          >
            {this.renderForm()}
          </CommonPanel>
        </div>

        <div className={classes.wrap}>
          {store.isAdmin && store.record ? (
            <CommonPanel
              rootStyle={{ marginTop: 20 }}
              title={t('Paskyros licencijos')}
            >
              <LicenceManager
                account={store.record._id}
                company={store.record.company}
              />
            </CommonPanel>
          ) : null}

        </div>
      </Accounts>
    )
  }
}

export default compose(withStyles(styles), withTranslation())(observer(Container));