import React from 'react';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';
import {
  withStyles,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableFooter,
  TableRow,
  TableCell,
  Typography,
  Link,
  Grid
} from '@material-ui/core';
import { observer } from 'mobx-react';
import { toJS } from 'mobx';

import { CommonDialog, CommonPanel, FormField2 } from 'components';
import styles from './styles';
import { twoDecimals } from 'utils/helpers';

class Container extends React.Component {
  state = {
    expanded: null
  };

  componentDidMount() {
    this.props.store.setRecordValue('months', this.props.months);
    this.props.store.loadData(this.props.editingItem);

    console.log('order item mounted...');
  }

  componentWillUnmount() {
    this.props.store.reset();
  }

  onSave() {
    const {
      t,
      store: { record }
    } = this.props;

    if (record.licences > 0 && record.price > 0) {
      this.props.onSave(toJS(this.props.store.record));
    } else {
      window.alert(
        !(record.licences > 0)
          ? t('Įveskite užsakomų darbo vietų (licencijų) skaičių')
          : t('Funkcionalumo kaina turi būti daugiau nei 0 EUR')
      );
    }
  }

  onClose() {
    this.props.onClose();
  }

  onPackageSelect(id) {
    this.props.store.setRecordValue('servicePackage', id);
  }

  onIndividualChecked(rule, dependants) {
    const { record, selectedService } = this.props.store;
    let rules = record.rules || [];

    const has = _.includes(rules, rule);

    // Add or remove rule to selection
    if (has) {
      rules = _.pull(rules, rule);
    } else {
      rules.push(rule);
    }

    // Adds dependant rules to selection if required
    if (!has && dependants.length) {
      _.forEach(dependants, dependantRule => {
        const hasDependant = _.includes(rules, dependantRule);

        if (!hasDependant) rules.push(dependantRule);
      });
    }

    // Remove dependant rules if any
    rules = _.filter(rules, ruleCode => {
      const dependencies = _.get(
        _.find(selectedService.rules, { rule: ruleCode }),
        'dependencies',
        []
      );
      const needsRemoving = dependencies.length
        ? !_.some(rules, r => _.includes(dependencies, r))
        : false;

      return !needsRemoving;
    });

    this.props.store.setRecordValue('rules', rules);
  }

  onChange({ name, value }) {
    this.props.store.setRecordValue(name, value);
    // this.props.store.setRecord({
    //   ...store.record,
    //   [name]: value,
    // });
  }

  getActivePackageClass(servicePackage) {
    const { record } = this.props.store;

    return record.servicePackage === servicePackage ? 'active' : '';
  }

  getIndividualPricingValue(months) {
    const { record, individualPackagePrices } = this.props.store;

    if (record.servicePackage !== 'individual') return null;

    const price = _.find(individualPackagePrices, { months });

    return price ? price.price : null;
  }

  selectService(serviceId) {
    this.props.store.setRecordValue('service', serviceId);
  }

  renderFormField({ name, ...field }) {
    const { record } = this.props.store;

    return (
      <FormField2
        {...field}
        name={name}
        // autoComplete={`project-${name}`}
        key={name}
        value={record ? record[name] : field.multiple ? [] : ''}
        onChange={this.onChange.bind(this)}
      />
    );
  }

  renderServiceList() {
    const { t } = this.props;
    const items = _.map(this.props.store.services, service => {
      const details = _.map(service.rules, ({ title }) => (
        <Typography key={`${service._id}-${title}`} variant="subtitle2">
          {title.trim()}
        </Typography>
      ));

      return (
        <CommonPanel
          key={service._id}
          title={service.title}
          subtitle={service.description}
          collapsible
          collapsed
          rootStyle={{ marginBottom: 10 }}
          toolbarActions={[
            <Button
              key="select-service"
              color="primary"
              onClick={() => this.selectService(service._id)}>
              {t('Pasirinkti')}
            </Button>
          ]}>
          {details}

          <Button
            style={{ marginTop: 10 }}
            variant="contained"
            color="primary"
            size="small"
            onClick={() => this.selectService(service._id)}>
            {t('Pasirinkti paslaugą')}
          </Button>
        </CommonPanel>
      );
    });

    return items;
  }

  renderPackageSelector(packageId) {
    const { classes, store } = this.props;
    const { record } = store;

    return (
      <input
        type="radio"
        name="selected-package"
        className={classes.packageRadio}
        checked={record.servicePackage === packageId}
        onChange={() => this.onPackageSelect(packageId)}
      />
    );
  }

  renderIndividualCheckbox(rule, dependencies = [], dependants = []) {
    const { record } = this.props.store;
    const checked =
      record.rules && record.rules.length
        ? _.includes(record.rules, rule)
        : false;

    // Disable all if selected package isn't individual
    let disabled = record.servicePackage !== 'individual';

    if (record.servicePackage === 'individual' && dependencies.length) {
      disabled = !_.some(record.rules, r => _.includes(dependencies, r));
    }

    return (
      <input
        type="checkbox"
        checked={!disabled ? checked : false}
        onChange={() => this.onIndividualChecked(rule, dependants)}
        disabled={rule == "estimates.layout" ? checked : disabled}
      />
    );
  }

  renderPricetable() {
    const { classes } = this.props;
    const {
      record,
      selectedService,
      servicePackages,
      settings
    } = this.props.store;

    const rows = _.map(selectedService.rules, rule => {
      let packagePrices = _.map(selectedService.packages, pkg => {
        const has = _.includes(pkg.rules, rule.rule);

        return (
          <TableCell
            key={`${pkg._id}-${rule.rule}`}
            style={{ textAlign: 'center' }}
            className={[
              classes.packageCell,
              this.getActivePackageClass(pkg.servicePackage)
            ].join(' ')}>
            {has ? '+' : null}
          </TableCell>
        );
      });

      return (
        <TableRow key={rule._id}>
          <TableCell colSpan={2}>{rule.title}</TableCell>
          {/* <TableCell style={{ textAlign: 'center' }}>{rule.priceMonth}</TableCell> */}
          <TableCell
            style={{ textAlign: 'center' }}
            className={[
              classes.packageCell,
              this.getActivePackageClass('individual')
            ].join(' ')}>
            {this.renderIndividualCheckbox(
              rule.rule,
              rule.dependencies,
              rule.dependants
            )}
          </TableCell>
          {packagePrices}
        </TableRow>
      );
    });

    const packageHeaders = [];
    const { t } = this.props;
    _.forEach(selectedService.packages, ({ servicePackage }) => {
      const pkg = _.find(servicePackages, { _id: servicePackage });

      packageHeaders.push(
        <TableCell
          key={`pkg-header-${servicePackage}`}
          className={[
            classes.packageHeaderCell,
            this.getActivePackageClass(servicePackage)
          ].join(' ')}>
          {this.renderPackageSelector(servicePackage)}
          {pkg ? pkg.title : servicePackage}
        </TableCell>
      );
    });

    return (
      <>
        <Typography variant="body1" style={{ marginBottom: 5 }}>
          <strong>{t('Pasirinkta paslauga')}:</strong> {selectedService.title}{' '}
          <Link href="#" onClick={() => this.props.store.reset(true)}>
            {t('Pakeisti')}
          </Link>
        </Typography>

        <TableContainer>
          <Table size="small" className={classes.rulesTable}>
            <TableHead>
              <TableRow>
                <TableCell rowSpan={2} colSpan={2}>
                  {t('Funkcionalumas')}
                </TableCell>
                {/* <TableCell rowSpan={2} style={{ textAlign: 'center' }}>{t('Kaina mėnesiui, EUR be PVM')}</TableCell> */}
                <TableCell style={{ textAlign: 'center' }}>
                  {t('Paslaugų paketai')}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  className={[
                    classes.packageHeaderCell,
                    this.getActivePackageClass('individual')
                  ].join(' ')}>
                  {this.renderPackageSelector('individual')}
                  {t('Individualus')}
                </TableCell>
                {packageHeaders}
              </TableRow>
            </TableHead>

            <TableBody>{rows}</TableBody>

            <TableFooter className={classes.rulesTableFooter}>
              {settings.services
                ? _.map(
                    settings.services.pricingRange,
                    ({ months, discountPercent }) => (
                      <TableRow key={`pricing-footer-${months}`}>
                        <TableCell
                          colSpan={2}
                          align="right"
                          className={[
                            classes.packageCell,
                            months === this.props.months ? 'active' : ''
                          ].join(' ')}>
                          {t('Kaina perkant')} {months} {t('mėn.')}, EUR:
                        </TableCell>
                        <TableCell
                          className={[
                            classes.packageCell,
                            this.getActivePackageClass('individual'),
                            months === this.props.months ? 'active' : '',
                            months === this.props.months &&
                            'individual' === record.servicePackage
                              ? classes.activePriceCell
                              : ''
                          ].join(' ')}
                          style={{ textAlign: 'center' }}>
                          {twoDecimals(
                            this.getIndividualPricingValue(months)
                          ) || '-'}
                        </TableCell>
                        {_.map(
                          selectedService.packages,
                          ({ servicePackage, prices }) => {
                            const priceList = _.find(prices, { months });
                            const price = priceList ? priceList.price : '-';

                            return (
                              <TableCell
                                className={[
                                  classes.packageCell,
                                  this.getActivePackageClass(servicePackage),
                                  months === this.props.months ? 'active' : '',
                                  months === this.props.months &&
                                  servicePackage === record.servicePackage
                                    ? classes.activePriceCell
                                    : ''
                                ].join(' ')}
                                style={{ textAlign: 'center' }}
                                key={`pricing-cell-${servicePackage}-${months}`}>
                                {twoDecimals(price)}
                              </TableCell>
                            );
                          }
                        )}
                      </TableRow>
                    )
                  )
                : null}

              <TableRow>
                <TableCell colSpan={2} align="right">
                  {t('Darbo vietų (licencijų) skaičius')}:
                </TableCell>
                <TableCell
                  style={{ textAlign: 'center' }}
                  className={[
                    classes.packageCell,
                    this.getActivePackageClass('individual')
                  ].join(' ')}>
                  {'individual' === record.servicePackage
                    ? this.renderFormField({
                        name: 'licences',
                        type: 'number',
                        style: { margin: 0, background: '#ffffff' },
                        className: classes.inputCenter,
                        min: 0,
                        step: 1,
                        max: 100
                      })
                    : null}
                </TableCell>
                {_.map(selectedService.packages, pkg => {
                  return (
                    <TableCell
                      key={pkg.servicePackage}
                      style={{ textAlign: 'center' }}
                      className={[
                        classes.packageCell,
                        this.getActivePackageClass(pkg.servicePackage)
                      ].join(' ')}>
                      {pkg.servicePackage === record.servicePackage
                        ? this.renderFormField({
                            name: 'licences',
                            type: 'number',
                            style: { margin: 0, background: '#ffffff' },
                            className: classes.inputCenter,
                            min: 0,
                            step: 1,
                            max: 100
                          })
                        : null}
                    </TableCell>
                  );
                })}
              </TableRow>

              <TableRow>
                <TableCell colSpan={2} align="right">
                  {t('Pirmos darbo vietos (licencijos) kaina')}, EUR
                </TableCell>
                <TableCell
                  style={{ textAlign: 'center', padding: 1 }}
                  className={[
                    classes.packageCell,
                    this.getActivePackageClass('individual')
                  ].join(' ')}>
                  {'individual' === record.servicePackage
                    ? twoDecimals(record.firstLicencePrice || 0)
                    : null}
                </TableCell>
                {_.map(selectedService.packages, pkg => {
                  return (
                    <TableCell
                      key={pkg.servicePackage}
                      style={{ textAlign: 'center', padding: 1 }}
                      className={[
                        classes.packageCell,
                        this.getActivePackageClass(pkg.servicePackage)
                      ].join(' ')}>
                      {pkg.servicePackage === record.servicePackage
                        ? twoDecimals(record.firstLicencePrice || 0)
                        : null}
                    </TableCell>
                  );
                })}
              </TableRow>

              <TableRow>
                <TableCell colSpan={2} align="right">
                  {t('Papildomos darbo vietos (licencijos) kaina')}, EUR
                </TableCell>
                <TableCell
                  style={{ textAlign: 'center', padding: 1 }}
                  className={[
                    classes.packageCell,
                    this.getActivePackageClass('individual')
                  ].join(' ')}>
                  {'individual' === record.servicePackage
                    ? twoDecimals(record.additionalLicencePrice || 0)
                    : null}
                </TableCell>
                {_.map(selectedService.packages, pkg => {
                  return (
                    <TableCell
                      key={pkg.servicePackage}
                      style={{ textAlign: 'center', padding: 1 }}
                      className={[
                        classes.packageCell,
                        this.getActivePackageClass(pkg.servicePackage)
                      ].join(' ')}>
                      {pkg.servicePackage === record.servicePackage
                        ? twoDecimals(record.additionalLicencePrice || 0)
                        : null}
                    </TableCell>
                  );
                })}
              </TableRow>

              <TableRow>
                <TableCell colSpan={2} align="right">
                  {t('Viso kaina už darbo vietas (licencijas)')}, EUR:
                </TableCell>
                <TableCell
                  style={{ textAlign: 'center', padding: 1 }}
                  className={[
                    classes.packageCell,
                    this.getActivePackageClass('individual')
                  ].join(' ')}>
                  {'individual' === record.servicePackage
                    ? twoDecimals(record.price || 0)
                    : null}
                </TableCell>
                {_.map(selectedService.packages, pkg => {
                  return (
                    <TableCell
                      key={pkg.servicePackage}
                      style={{ textAlign: 'center', padding: 1 }}
                      className={[
                        classes.packageCell,
                        this.getActivePackageClass(pkg.servicePackage)
                      ].join(' ')}>
                      {pkg.servicePackage === record.servicePackage
                        ? twoDecimals(record.price || 0)
                        : null}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>

        <Grid container>
          <Grid item xs={6}>
            <Typography
              variant="body1"
              dangerouslySetInnerHTML={{
                __html: _.get(settings, 'services.pricingInfoHtml')
              }}
            />
          </Grid>
        </Grid>
      </>
    );
  }

  renderForm() {
    const { record } = this.props.store;

    return (
      <>
        {!record.service ? this.renderServiceList() : null}

        {record.service ? this.renderPricetable() : null}
      </>
    );
  }

  render() {
    const { classes, editingItem, t } = this.props;
    const { record } = this.props.store;

    let actions = [];

    if (record.service) {
      actions.push(
        <Button
          key="save"
          onClick={this.onSave.bind(this)}
          color="primary"
          variant="contained">
          {!editingItem ? t('Įtraukti į užsakymą') : t('Išsaugoti')}
        </Button>
      );
    }

    actions.push(
      <Button
        key="cancel"
        onClick={() =>
          record.service ? this.props.store.reset(true) : this.onClose()
        }
        color="primary">
        {t('Atšaukti')}
      </Button>
    );

    return (
      <CommonDialog
        visible
        title={
          !record.service
            ? t('Pasirinkite paslaugą')
            : t('Darbo vietų (licencijų) užsakymas')
        }
        onClose={() => this.props.onClose()}
        actions={actions}
        maxWidth={!record.service ? 'sm' : 'md'}>
        <div className={classes.formWrap}>{this.renderForm()}</div>
      </CommonDialog>
    );
  }
}

export default compose(
  withStyles(styles),
  withTranslation()
)(observer(Container));
