import React from 'react';
import { compose } from 'recompose';
import _ from 'lodash';
import {
  withStyles,
  Grid,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { observer, Provider } from 'mobx-react';

import InvestmentViewer from 'views/InvestmentViewer';
import DetailsBlock from './components/DetailsBlock';
import OptionsBlock from './components/OptionsBlock';
import ResultBlock from './components/ResultBlock';
import { TrialHandler, LicenceRequestForm } from 'components';
import { getQueryVariable, getCurrentAccount } from 'utils/helpers';
import { EDU_MODE } from 'utils/config';

import api from 'utils/api';

import styles from './styles';

const SERVICE_CODE = 'investments';
const SERVICE_CODE_DB = 'BIMGates_AA';

class Container extends React.Component {
  state = {
    isTrial: false,
    showTrialMessage: false,
    canAccessService: false,
  }

  async componentDidMount() {
    if (getQueryVariable('trial')) {
      this.setState({ isTrial: true });
      return;
    }

    if (_.includes(getCurrentAccount().trialServices, SERVICE_CODE)) {
      this.setState({ showTrialMessage: true });
    }

    const response = await api.post(`/licences/licence-valid`, { service: SERVICE_CODE_DB });
    this.setState({ canAccessService: response.data.validLicence });

    // TODO runs multiple times
    if (response.data.validLicence) this.props.store.init();
  }

  componentWillUnmount() {
    this.props.store.reset();
  }

  renderService() {

    return (
      <Grid container>
        <Grid item md={5} style={{ marginBottom: 20 }}>
          <DetailsBlock />
        </Grid>

        <Grid item xs={12} style={{ marginBottom: 20 }}>
          <OptionsBlock />
        </Grid>

        <Grid item xs={12} style={{ marginBottom: 20 }}>
          <ResultBlock />
        </Grid>
      </Grid>
    )
  }

  renderTrial() {

    return (
      <TrialHandler service={SERVICE_CODE} />
    )
  }

  renderTrialMessage() {

    return EDU_MODE ? null : (
      <div style={{ margin: 10 }}>
        <Alert 
          variant="filled"
          severity="info"
          // onClose={() => this.setState({ showTrialMessage: false })}
        >
          Jūs naudojate bandomąją (laikinai nemokamą) paslaugos versiją.
        </Alert>
      </div>
    )
  }

  renderLicenceRequest() {

    return (
      <LicenceRequestForm
        serviceCode={SERVICE_CODE}
      />
    )
  }

  render() {
    const { classes, store } = this.props;
    const { isTrial, canAccessService, showTrialMessage } = this.state;

    return (
      <InvestmentViewer
        title="Statinių palyginamieji rodikliai"
        beforeMainContent={!isTrial && showTrialMessage && this.renderTrialMessage()}
      >
        <Provider store={store}>
          
          <div className={classes.wrap}>
            {!isTrial ? canAccessService ? this.renderService() : this.renderLicenceRequest() : this.renderTrial()}
          </div>

        </Provider>
      </InvestmentViewer>
    )
  }
}

export default compose(withStyles(styles))(observer(Container));